@font-face {
  font-family: "Rubik";
  src: url("../../fonts/Rubik/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik-Medium";
  src: url("../../fonts/Rubik/Rubik-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Rubik" !important;
}

.container-fluid a, a:hover, .mainContentWizard a, a:hover {
  color: #4783c4;
  font-weight: 400;
}

.container {
  line-height: 1.6em;
 
}

.bp3-overlay-backdrop {
  background-color: rgba(16, 22, 26, 0.4) !important;
}

.pill {
  width: 100%;
  height: 16px;
  border-radius: 3px;
  display: block;
  margin-top:6px;
}

.small-pill {
  width: 20px;
  margin: 6px;
  display: flex;
}

.tier-weekly-summary-histogram {
  min-height: 15px;
  margin-top: 15px;
  margin-bottom: 0px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(0,0,0,0.1);
  z-index: 0;
}

.tier-weekly-summary-histogram-axis {
  color:rgb(128, 128, 128);
  height: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  margin-top: -10px;
  font-family: Rubik !important;
}

.histogram-element {
  border-right: 2px #fff solid;
}

.histogram-element:last-child {
  border: 0px #fff solid;
}

/* .tier-weekly-summary-histogram .bp3-slider-axis{
  width: 300px;
} */

.element-super-off-peak {
  height: 30px;
  background:#7608c9;
  position: absolute;
}
.element-off-peak {
  height: 30px;
  background:#1d9a29;

  position: absolute;
}
.element-mid-peak {
  height: 30px;
  background:#f3e039;

  position: absolute;

}
.element-on-peak {
  height: 30px;
  background:#e82121;

  position: absolute;
}
.element-critical-on-peak {
  height: 30px;
  background:#4583c7;
  position: absolute;
}


.bp3-select-item-super-off-peak::before{
  content:"\25CF";
  color:#7608c9;
}

.rate-period-super-off-peak .selectedTier-tick { 
  position: absolute;
  top: -12px;
  left: 0px;
  width: 18px;
  height: 18px;
  background-color: #7608c9;
  border-radius: 50%;
  color: #fff;
  padding-top: 6px;
  font-size: 10px;
  line-height: 6px;
  text-align: center;
}

.tier-preview-box-super-off-peak {
  margin: 20px;
  width: 260px;
  border-left: 5px #7608c9 solid;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(16, 22, 26, 0.1) 0px 0px 8px 2px;
  align-self: center;
  position: relative;
}

.tier-preview-box-off-peak {
  margin: 20px;
  width: 260px;
  border-left: 5px #1d9a29 solid;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(16, 22, 26, 0.1) 0px 0px 8px 2px;
  align-self: center;
  position: relative;
}


.charge-preview-bow {
  margin: 20px;
  width: 260px;
  border-left: 5px #4684c8 solid;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(16, 22, 26, 0.1) 0px 0px 8px 2px;
  align-self: center;
  position: relative;
}

.rate-period-off-peak .selectedTier-tick { 
  position: absolute;
  top: -12px;
  left: 0px;
  width: 18px;
  height: 18px;
  background-color: #1d9a29;
  border-radius: 50%;
  color: #fff;
  padding-top: 6px;
  font-size: 10px;
  line-height: 6px;
  text-align: center;
}

.tier-preview-box-mid-peak {
  margin: 20px;
  width: 260px;
  border-left: 5px #f3e039 solid;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(16, 22, 26, 0.1) 0px 0px 8px 2px;
  align-self: center;
  position: relative;
}

.rate-period-mid-peak .selectedTier-tick { 
  position: absolute;
  top: -12px;
  left: 0px;
  width: 18px;
  height: 18px;
  background-color: #f3e039;
  border-radius: 50%;
  color: #000;
  padding-top: 6px;
  font-size: 10px;
  line-height: 6px;
  text-align: center;
}

.tier-preview-box-on-peak {
  margin: 20px;
  width: 260px;
  border-left: 5px #e82121 solid;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(16, 22, 26, 0.1) 0px 0px 8px 2px;
  align-self: center;
  position: relative;
}

.rate-period-on-peak .selectedTier-tick { 
  position: absolute;
  top: -12px;
  left: 0px;
  width: 18px;
  height: 18px;
  background-color: #e82121;
  border-radius: 50%;
  color: #fff;
  padding-top: 6px;
  font-size: 10px;
  line-height: 6px;
  text-align: center;
}

.tier-preview-box-critical-on-peak {
  margin: 20px;
  width: 260px;
  border-left: 5px #4583c7 solid;
  padding: 15px;
  border-radius: 6px;
  box-shadow: rgba(16, 22, 26, 0.1) 0px 0px 8px 2px;
  align-self: center;
  position: relative;
}


.rate-period-critical-on-peak .selectedTier-tick { 
  position: absolute;
  top: -12px;
  left: 0px;
  width: 18px;
  height: 18px;
  background-color: #4583c7;
  border-radius: 50%;
  color: #fff;
  padding-top: 6px;
  font-size: 10px;
  line-height: 6px;
  text-align: center;
}

.tier-preview-times, .charge-preview-value {
  font-size: 12px;
  color: rgba(128,128,128,0.75);
}

.overrides-schedules {
  width: 100%;
  margin-top: 60px;
  border-top: 1px rgba(0,0,0,0.4) dotted;
}

.tier-preview-rate {
  font-family: Rubik-Medium !important;
  font-size: 12px;
  top: -10px;
  position: relative;
  color: rgba(128,128,128,0.35);
}

.tiers-preview-deletebtn {
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.tier-days {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  border-top: 1px rgba(0,0,0,0.4) dotted;
  padding-top: 30px;
  width: 100%;
}

.tiers-charges {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  border-top: 1px rgba(0,0,0,0.4) dotted;
  padding-top: 30px;
}

.hi-low-explain {
  max-width: 260px;
  margin-top: 60px;
}

.bp3-intent-off-peak {
  background-color: #1d9a29;
}

.bp3-select-item-off-peak::before{
  content:"\25CF";
  color:#1d9a29;
}

.bp3-intent-super-off-peak {
  background-color: #7608c9;
}

.bp3-select-item-off-super-off-peak::before{
  content:"\25CF";
  color:#7608c9;
}

.bp3-intent-mid-peak {
  background-color: #f3e039;
}

.bp3-select-item-mid-peak::before{
  content:"\25CF";
  color:#f3e039;
}

.bp3-intent-on-peak {
  background-color: #e82121;
}

.bp3-select-item-on-peak::before{
  content:"\25CF";
  color:#e82121;
}

.bp3-intent-critical-on-peak {
  background-color: #4684c8;
}

.bp3-select-item-critical-on-peak::before{
  content:"\25CF";
  color:#4684c8;
}

.bp3-select.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.drag-drop-tiers {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.drag-drop-tiers div {
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
  
}

.drag-drop-tiers div:hover{
  cursor: grab;
  border-color: rgba(0,0,0,0.3);
  transition: border 0.25s ease-in;
}

.drag-drop-tier-rate {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 6px;
  font-family: Rubik-Medium;
  text-align: center;
  width: 100%;
}

.hold {
  background-color: #afafaf21;
  border: 1px rgba(0,0,0,0.05) solid !important;
  opacity: 0.5;
  transition: all 0s ease-in;
}

.hovered {
  border-color: #000 !important;
}

.tiers-disabled, .tiers-disabled div:hover {
  cursor: not-allowed !important;
  opacity: 1;
  filter: grayscale(1);
}

.addcharge-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  
}

.wizardCenteredBox a, a:hover{
  color: rgb(217, 130, 43);
  font-weight: 400;

}

.mainContentWizard a:hover {
  text-decoration: underline !important;
}
table {
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12.8px;
  font-weight: bold;
  text-transform: uppercase;
}


input {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}

.loginTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #4783c4;
}

.dashboardContainer {
  background: rgb(255,255,255);
}

.navLink {
  color: #bdbdbd;
  cursor: "pointer";
  display: "block";
  padding: 0;
  border-bottom: 4px solid #fdfdfd;
  outline: none;
}

.navLink:hover {
  color: #4684c8;
  border-bottom: 4px #4685c844 solid;
}

.navLink.active {
  color: #4684c8;
  border-bottom: 4px #4685c844 solid;
}

.mobileLinks .navLink {
  border-left: 4px solid #fdfdfd;
}

.mobileLinks .navLink:hover {
  color: #4684c8;
  border-left: 4px #4685c844 solid;
  border-bottom: 4px solid #fdfdfd;
}

.mobileLinks .navLink.active {
  border-left: 4px #4685c844 solid;
  border-bottom: 4px solid #fdfdfd;
}

.navLinkWithSubmenu ul {
  display: none;
}

.navLinkWithSubmenu:hover ul {
  display: block;
  position: relative;
}

.navLinkWithSubmenu.submenu:hover ul {
  display: block;
  position: relative;
}

.hideSubmenu {
  opacity: 0;
  visibility: hidden;
}

.languagesSubmenu {
  cursor: pointer;
}

.navLinkWithSubmenu.languagesSubmenu:hover ul {
  display: block;
  position: relative;
}
.navLinkWithSubmenu li {
  padding-top: 0;
  padding-bottom: 0;
}

.hideLanguagesSubmenu {
  opacity: 0;
  visibility: hidden;
}

.subNavLink {
  color: #bdbdbd;
  cursor: "pointer";
  display: "block";
  outline: none;
  padding: 0;

}

.subNavLink:hover {
  color: #4684c8;
}

.subNavLink.active {
  color: #4684c8;
}

.setupSubNavLink {
  color: #bdbdbd;
  cursor: "pointer";
  display: flex;
  width: max-content;
  padding: 0;
  outline: none;
  text-transform: uppercase;
}

.setupSubNavLink:hover {
  color: #4684c8;
}

.setupSubNavLink.active {
  color: #4684c8;
}

.plusIcon {
  position: absolute;
  left: -15px;
  top: 18px;
}

.plusIconNoDevice {
  position: absolute;
  left: -15px;
  top: 11px;
}

.devicesList {
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.languagesList {
  border-bottom: none;
}

.position-relative{
  position: relative;
  width: 50%;
}

.rate-plans-select {
  width: 50%;
  flex-direction: row;
  display: flex;
}

.ratePlanCustomizeButton{ 
  height: 40px;
  width: 100%;
}

.rate-plans-select .bp3-select { 
  display: flex;
  flex: 1;
}

.highlight-input {
  border: 3px #ffbe96 solid;
  border-radius: 3px;
  height: 46px;
}
.highlight-input .bp3-button-text {
  opacity: 0.5;

}
.customRatePlan-dialog {
  width: 800px !important;
}
.rateWizard {
 padding-bottom: 40px;
}

.ratePeriods {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.rate-period-super-off-peak, .rate-period-off-peak, .rate-period-mid-peak, .rate-period-on-peak, .rate-period-critical-on-peak {
  position: relative;
  justify-content: center;
  align-items: flex-start;
  border: 1px rgba(230, 230, 230, 0.5) solid;
  border-radius: 6px;
  padding: 6px;
  width: 130px;
  height: 100px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  transition: all 0s ease-in;
}

.selectable-tiers .rate-period-super-off-peak, .selectable-tiers .rate-period-off-peak, .selectable-tiers .rate-period-mid-peak, .selectable-tiers .rate-period-on-peak, .selectable-tiers .rate-period-critical-on-peak {
  height: 120px;
  justify-content: flex-start;
}

.rate-period-title{
  font-family: Rubik-Medium;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.ratePeriods-explain {
  display: flex;
  margin-bottom: 60px;
  
  flex-wrap: wrap;
}

.rate-period-explain-low, .rate-period-explain-high {
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: center;
  align-items:flex-start;
  padding: 6px;
  margin-top: 14px;
  font-size: 12.4px;
  line-height: 18px;
}

.rateWizard .bp3-slider {
  width: 100%;
  display: flex;
}
.bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start .bp3-slider-label {
  transform: translate(-70px);
}
.ratePlanInput{
  position: relative;
  max-width: 300px !important;
  margin: 0 auto;
}
.overrideInputs {
  display: block;
  position: relative;
  width: 100%;
  max-width: 300px !important;
}
.tierScheduler {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.customRatePlan-summary-section {
  border-bottom: 1px rgba(0,0,0,0.4) dotted;
  padding-bottom: 30px;
  padding-top: 30px;
}

.summary-charges {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

}

.customRatePlan-summary-section .info-label {
  font-family: Rubik-Medium;
  padding-right: 10px;
}

.radioGroup-charges{
  max-width: 300px;
  margin: 0 auto;
  margin-top: -15px;
}

.tierSelector {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.add-tier{
  max-width: 300px;
}

.bp3-slider-axis{
  display: block;
  color: rgba(128, 128, 128, 1)
}

.tierTimeLine {
  display: flex;
  flex: 1 1;
  position: relative;
  margin-top: 60px;
  width: 100%;
  padding: 20px 40px;
    padding-bottom: 0px;
  border: 2px rgba(0,0,0,0.1) dashed;
  padding-bottom: 27px;
  border-radius: 5px;
  background: rgba(0,0,0,0.01);
}

.tierTimeLine .bp3-slider-track, .tierTimeLine .bp3-slider-track div {
  height: 20px;
}

.tierTimeLine .bp3-slider-axis {
  padding-top: 10px;
}

.tierTimeLine .bp3-slider-handle {
  height: 24px;
  width: 8px;
  margin-left: 7px;
  margin-top: 2px;
}
.bp3-slider-handle:hover{
  box-shadow: none;
}

.bp3-slider-handle.bp3-start, .bp3-slider-handle.bp3-end {
  width: 4px;
}

.inputLabel{
  text-align: left;
  font-size: 14px;
  color:rgba(128,128,128,1);
  max-width: 300px;
  margin: 0 auto; 
}

.ratePlanInput input{
  max-width: 300px !important;
}

.timeline {
  margin-top: 80px;
  height: 40px;
  width: 100%;
  position: absolute;
  background: url("../../assets/ui/timeline-bg.svg") no-repeat bottom;  
  background-size: contain;
  margin-left: -40px;
  opacity: 0.5;
}

.timeline-histogram {
  margin-top: 40px;
  margin-left: -20px !important;
}

.tiers-added-count {
  position: absolute;
  right: 0px;
  top: -34px;
  font-size: 12px;
}

.bp3-bold {
  font-family: Rubik-Medium;
}

.newTier {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: calc(100% - 60px);
  margin-top: 30px;
}

.newTier-select{
  display: flex;
  flex: 1;
  position: relative;
  width: 140px;
}

.tierLabel {
  position: absolute;
  top: -25px;
  font-size: 12px;
  color:rgb(128, 128, 128);
}

.newTier .selectDropDown{
  width: 100%;
}

.newTier .bp3-menu {
  width: 120px !important;
}

.rateWizard .bp3-menu-item {
  text-transform: capitalize;
}

.tiers-preview {
  margin-top: 50px; 
  height: 100%;
  width: 100%;
  /* background-color: rgba(128, 128, 128, 0.2); */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* border-top: 1px rgba(0,0,0,0.2) dotted; */
  padding-top: 30px;
}

.add-tier {
  position: absolute;
  left: 0px;
  top: 6px;
}

.bailout-newtier{
  position: absolute;
  left: 0px;
  top: 6px;
}

.newTier-addTier {
  display: flex;
  padding-left: 10px;
  height: 40px;
}

.days-of-the-week {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.days-of-the-week .bp3-control.bp3-large {
  font-size: 12px !important;
}

.save-schedule {
  margin-top: 20px;
  display: block;
}

.bp3-checkbox {
  color: #000;
  margin: 4px;
}

.tier-weekly-summary-title {
  border-bottom: 1px rgba(0,0,0,0.4) dotted;
  color:rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  font-size: 14px;
  text-transform: uppercase;
}

.tier-weekly-summary-title:last-child {
  border-bottom: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.loader {
  width: 200px;
  height: 4px !important;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  transition: opacity 0.3s ease-out;
  box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
}

.loaderDashboard {
  position: relative !important;
  top: 0;
  left: 0;
  margin-left: 0;
}

.loader::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #4684c8;
  border-radius: 20px;
  animation: grow 2s ease-in-out infinite;
}

.loader.loaded {
  display: none;
}

.successIcon {
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.fadeIcon {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.bp3-toast {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10px;
}

.bp3-toast,
.bp3-button-text,
.bp3-dialog {
  font-family: "Rubik";
}

/* profile categories */

.categoryClosed {
  max-height: 0;
  overflow: hidden;
}

.categoryOpened {
  max-height: 3000px;
  overflow: hidden;
  padding: 4px;
}

.helpAnswer {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 30px;
}

.helpAnswer li {
  text-align: left;
}

.helpAnswer ol li::marker {
  color: #4684c8;
  font-size: 1.2em;
}

.helpAnswer img {
  width: 100%;
  max-width: 300px;
  display: flex;
  margin: 0 auto;
}

.helpAnswer a,
visited,
.bp3-alert a,
visited {
  color: rgb(71, 131, 196);
  font-weight: 400;
}

.helpAnswer p {
  font-family: "Rubik";
  font-size: 16px;
  text-align: left;
}

.helpAnswer strong {
  font-weight: 400;
}

.helpAnswer ul {
  font-family: "Rubik";
  font-size: 16px;
  margin: 0;
  margin-bottom: 12px;
  padding-left: 20px;
}

.helpAnswer ol {
  font-family: "Rubik";
  font-size: 16px;
  margin: 0;
  margin-bottom: 12px;
  padding-left: 20px;
}

.termsModal .bp3-alert-footer {
  display: none;
}

.extendedContent {
  width: calc(100% + 120px) !important;
  position: relative !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
}

.extendPageTitle {
  width: calc(100% + 120px) !important;
}

.cut {
  font-variant: normal;
  left: 120px;
}

.bp3-dialog {
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  max-width: 800px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 0 1px rgb(16 22 26 / 10%),
    0 4px 8px rgb(16 22 26 / 20%), 0 18px 46px 6px rgb(16 22 26 / 20%);
  box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 4px 8px rgb(16 22 26 / 20%),
    0 18px 46px 6px rgb(16 22 26 / 20%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 30px 0;
  padding-bottom: 20px;
  pointer-events: all;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: 500px;
  background-color: rgb(255, 255, 255);
  margin-left: 25px;
  margin-right: 10px;
  min-width: 320px;
}

.calendarModal {
  height: 500px;
}

.peak {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.notif {
  position: relative;
  font-family: "Rubik";
  font-size: 14px;
  line-height: 16px;
  padding: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: italic;
  padding-top: 5px;
  border-radius: 0.6em;
  margin-top: -45px;
  margin-left: 0px;
  width: 100%;
  
}

.notif-offline {
  background: rgb(219, 55, 55);
  color: white;
  width: 240px;
  left: 50%;
  margin-left: -120px;
}

.notif-cached {
  background:rgb(128, 128, 128);
  color: white;
  width: 240px;
  left: 50%;
  margin-left: -120px;
}

#container {
  min-height: 700px;
  overflow: hidden;
}

.highcharts-container,
.highcharts-container svg {
  width: 100% !important;
}

.highcharts-legend-item text {
  cursor:initial !important;
}

.highcharts-legend-item text:hover {

  color: grey !important;
  fill: grey !important;
}

.rightNowChart .highcharts-radial-axis-labels text:first-child {
    display: none;
}

.widgetsSwitch
  .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right)
  .bp3-control-indicator {
  background-color: #d8d8d8;
  height: 30px;
  padding-top: 5px;
}

.widgetsSwitchWhite
  .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right)
  .bp3-control-indicator {
  background-color: rgb(243, 243, 243);
  height: 30px;
  padding-top: 5px;
}

.widgetsSwitchWhite .bp3-control.bp3-switch .bp3-control-indicator::before {
  height: 1em;
  width: 1em;
  left: 0.1em;
  top: 0.15em;
}

.widgetsSwitch .bp3-control.bp3-switch .bp3-control-indicator::before {
  height: 1em;
  width: 1em;
  left: 0.1em;
  top: 0.15em;
}

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
  left: calc(100% - 1.3em);
  top: 0.15em;
}

.bp3-alert-body {
  display: block;
}

.bp3-control.bp3-switch .bp3-switch-inner-text {
  color: #5c7080;
  font-family: "Rubik";
  font-weight: 400;
  padding: 0px 3px;
}

.bp3-dialog-close-button {
  transform: scale(1.4);
  color: black;
  filter: brightness(0);
}

.react-calendar__navigation {
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 0px;
}

.react-calendar__viewContainer {
  margin-top: 1em;
}

.bp3-overlay-open {
  overflow-y: scoll !important;
}

.bp3-html-select,
.bp3-select {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.fwTooltip {
  display: none;
}

/* title-tip down */
.title-tip[title]:hover {
  position: relative;
}

.title-tip[title]:hover:before {
  position: absolute;
  content: attr(title);
  background: rgba(128, 128, 128, 1);
  border-radius: 6px;
  border: 1px rgba(128, 128, 128, 1) solid;
  position: absolute;
  width: 120px;
  padding: 6px;
  text-align: center;
  margin: 0 auto;
  margin-right: 15px;
  top: 30px;
  right: -63px;
  color: white;
  z-index: 6;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0);
}
.title-tip[title]:hover:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid rgb(128, 128, 128);
  z-index: 6;
}



.title-small-tip[title]:hover {
  position: relative;
}

.icon-battery-unknown {
  font-size: 12px;
  position: absolute;
  top: 4px;
  right: 11px;
  line-height: 18px;
  /* transform: rotate(-90deg); */
}

.icon-signal-unknown {
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 8px;
  line-height: 13px;
  background-color: white;
}


.title-small-tip[title]:hover:before {
  position: absolute;
  content: attr(title);
  background: rgba(128, 128, 128, 1);
  border-radius: 6px;
  border: 1px rgba(128, 128, 128, 1) solid;
  position: absolute;
  width: 60px;
  padding: 6px;
  text-align: center;
  margin: 0 auto;
  margin-right: 15px;
  top: 30px;
  right: -31px;
  color: white;
  z-index: 1;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0);
}
.title-small-tip[title]:hover:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid rgb(128, 128, 128);
}


.title-unknown-tip[title]:hover:before {
  position: absolute;
  content: attr(title);
  background: rgba(128, 128, 128, 1);
  border-radius: 6px;
  border: 1px rgba(128, 128, 128, 1) solid;
  position: absolute;
  width: 200px;
  padding: 6px;
  text-align: center;
  margin: 0 auto;
  margin-right: 15px;
  top: 30px;
  right: -16px;
  color: white;
  z-index: 1;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0);
}
.title-unknown-tip[title]:hover:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  margin-left: 10px;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid rgb(128, 128, 128);
}

.title-unknown-signal-tip[title]:hover:before {
  position: absolute;
  content: attr(title);
  background: rgba(128, 128, 128, 1);
  border-radius: 6px;
  border: 1px rgba(128, 128, 128, 1) solid;
  position: absolute;
  width: 200px;
  padding: 6px;
  text-align: center;
  margin: 0 auto;
  margin-right: 15px;
  top: 30px;
  right: 18px;
  color: white;
  z-index: 1;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0);
}
.title-unknown-signal-tip[title]:hover:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  margin-left: -24px;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid rgb(128, 128, 128);
}

.fwButton:hover .fwTooltip {
  display: block;
  background: rgba(128, 128, 128, 1);
  border-radius: 6px;
  border: 1px rgba(128, 128, 128, 1) solid;
  position: absolute;
  width: 120px;
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  margin-right: 15px;
  top: 30px;
  right: -63px;
  color: white;
  z-index: 1;
  font-size: 14px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0);
}
.fwTooltip::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -5px;
  width: 0;
  height: 0;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid rgb(128, 128, 128);
}

.selectDropDown .bp3-button {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  width: 100%;
  max-width: 280px;
  background-color: white;
  padding-top: 2px;
  text-align: left;
}

.selectDropDown .bp3-button:hover {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  width: 100%;
  max-width: 280px;
  background-color: white;
  padding-top: 2px;
  text-align: left;
}

.selectDropDown .bp3-button span {
  font-family: Rubik;
  font-weight: 100;
  font-size: 16px;
  height: 40px;
  line-height: 44px;
  overflow: hidden;
  width: 100%;
  text-transform: capitalize;
  color: #5c7080;
}

.bp3-input.bp3-error,
.bp3-button.bp3-error,
.bp3-timezone-picker.bp3-error button {
  border: 1px #f55656 solid;
}

.customRatePlanError {
  background-color: rgb(219, 55, 55); 
  border-radius: 6px; 
  border: 1px solid rgba(219, 55, 55, 0.2); 
  position: absolute; 
  width: 100%; 
  max-width: 280px; 
  padding: 10px; 
  text-align: center; 
  margin: 0px 15px 0px auto; 
  top: 70px; 
  color: white; 
  z-index: 1; 
  font-size: 14px; 
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 15px, rgba(0, 0, 0, 0) -5px -5px 15px;
}

.customRatePlanError-small {
  width: 116px;
  top: 113px;
}

.errorTooltip::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -5px;
  width: 0;
  height: 0;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid rgb(219, 55, 55);
}

.bp3-input.bp3-error:focus {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px #f55656, 0 0 0 3px rgba(189, 19, 19, 0),
    inset 0 1px 1px rgba(26, 16, 16, 0);
  box-shadow: 0 0 0 1px #f55656, 0 0 0 3px rgba(189, 19, 19, 0),
    inset 0 1px 1px rgba(26, 16, 16, 0);
}

.bp3-select-noScroll {
  overflow-y: hidden;
}

.bp3-select-wizard {
  margin: 0 auto;
  position: relative;
}

.bp3-input-wizard {
  margin: 0 auto;
  position: relative;
  max-width: 280px;
}

#appliancesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  height: 280px;
}

#appliancesContainer > div {
  margin: 5px;
  width: 80px;
  height: 80px;
}

#appliancesContainer > div img {
  max-height: 80px;
  opacity: 0.5;
  transition: opacity 0.2s ease-in;
}

#appliancesContainer > div img:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

#appliancesContainer > div span {
  display: inline-block;
  font-family: "Rubik";
  font-size: 14px;
  color: rgb(128, 128, 128);
}

/* negative paddings are treated as 0 */
#appliancesContainer > div {
  /* up to 3 boxes in a row */
  flex-basis: 20%;
}

#appliancesContainer > div:nth-child(3n + 1) {
  clear: left;
}

.bp3-menu {
  font-size: 16px;
  font-family: "Rubik";
  background-color: #f5f8fa;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.8)),
    to(rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
  -webkit-box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%),
    inset 0 -1px 0 rgb(16 22 26 / 10%);
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%),
    inset 0 -1px 0 rgb(16 22 26 / 10%);
  color: #182026;
  max-height: 300px;
  min-width: 100px;
  text-align: left;
  overflow-y: auto;
}

.countryCode {
  text-align: right;
}

.filterableDropDown .bp3-popover-target {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  font-family: Rubik;
  font-weight: 100;
  width: 100%;
  max-width: 280px;
  background-color: white;
}

.filterableDropDown .bp3-button {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  width: 100%;
  max-width: 280px;
  background-color: white;
  padding-top: 2px;
  text-align: left;
}

.filterableDropDown .bp3-button:hover {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  width: 100%;
  max-width: 280px;
  background-color: white;
  padding-top: 2px;
  text-align: left;
}

.filterableDropDown .bp3-button span {
  font-family: Rubik;
  font-weight: 100;
  font-size: 16px;
  height: 40px;
  line-height: 44px;
  overflow: hidden;
  width: 100%;
}

.filterableDropDown .bp3-icon {
  display: none;
}

.toastLeftSpacing {
  margin-left: 100px;
}

.selectable-tiers div  {
  opacity:1;
  cursor: pointer;
  display: block;
}

.selectable-tiers div:hover{
  opacity: 1;
  cursor: pointer;
}

.selectable-tiers div.selected  {
  opacity: 1;
}

.selectable-tiers .bp3-input {
  margin-top: 14px !important;
}

.selectDropDown .bp3-popover-target {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  font-family: Rubik;
  font-weight: 100;
  width: 100%;
  max-width: 280px;
  background-color: white;
}

.countryCodeDropDown .bp3-popover-target {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  font-family: Rubik;
  font-weight: 100;
  width: 100%;
  max-width: 280px;
  background-color: white;
  width: 51px;
}

.countryCodeDropDown .bp3-button {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  width: 100%;
  max-width: 280px;
  background-color: white;
  padding: 4px;
  padding-top: 2px;
  text-align: left;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%),
    inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
  box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%),
    inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
}

.countryCodeDropDown .bp3-button:hover {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: rgb(128, 128, 128);
  width: 100%;
  max-width: 280px;
  background-color: white;
  padding: 4px;
  padding-top: 2px;
  text-align: left;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%),
    inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
  box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%),
    inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
}

.countryCodeDropDown .bp3-button span {
  font-family: Rubik;
  font-weight: bold;
  font-size: 12px;
  height: 40px;
  line-height: 44px;
  overflow: hidden;
  width: 100%;
  color: #5c7080;
  text-align: right;
  padding: 0px;
}

.selectDropDown .bp3-icon {
  display: none;
}

.bp3-control-group .bp3-select::after, .bp3-control-group .bp3-html-select::after, .bp3-control-group .bp3-select > .bp3-icon, .bp3-control-group .bp3-html-select > .bp3-icon {

  z-index: 1000000;
}

.bp3-select-ratePlan, .bp3-select-ratePlan button, .bp3-select-ratePlan span {
  max-width: 300px !important;
  margin: 0 auto;
}

#Argentina::before,
#AR::before {
  content: url("../../assets/flags/ar.svg");
  width: 14px;
  height: 14px;
}

#Australia::before,
#AU::before {
  content: url("../../assets/flags/au.svg");
  width: 14px;
  height: 14px;
}

#Bulgaria::before,
#BG::before {
  content: url("../../assets/flags/bg.svg");
  width: 14px;
  height: 14px;
}

#Canada::before,
#CA::before {
  content: url("../../assets/flags/ca.svg");
  width: 14px;
  height: 14px;
}

#Honduras::before,
#HN::before {
  content: url("../../assets/flags/hn.svg");
  width: 14px;
  height: 14px;
}

#Mexico::before,
#MX::before {
  content: url("../../assets/flags/mx.svg");
  width: 14px;
  height: 14px;
}

#NewZealand::before,
#NZ::before {
  content: url("../../assets/flags/nz.svg");
  width: 14px;
  height: 14px;
}

#UnitedStates::before,
#US::before {
  content: url("../../assets/flags/us.svg");
  width: 14px;
  height: 14px;
}

.bp3-control-group #Canada::before,
.bp3-control-group #Argentina::before,
.bp3-control-group #Australia::before,
.bp3-control-group #Bulgaria::before,
.bp3-control-group #Honduras::before,
.bp3-control-group #Mexico::before,
.bp3-control-group #NewZealand::before,
.bp3-control-group #UnitedStates::before,
.bp3-control-group #CA::before,
.bp3-control-group #AR::before,
.bp3-control-group #AU::before,
.bp3-control-group #BG::before,
.bp3-control-group #HN::before,
.bp3-control-group #MX::before,
.bp3-control-group #NZ::before,
.bp3-control-group #US::before {
  width: 14px;
  height: 14px;
  position: absolute;
  z-index: 10;
  left: 5px;
  top: 2px;
  opacity: 0.7;
}

#globe {
  width: 14px;
  height: 14px;
  position: absolute;
  z-index: 10;
  left: 5px;
  top: -2px;
  opacity: 1;
}

.bp3-popover-content a:hover {
  color: #182027 !important;

  background: rgba(128, 128, 128, 0.2);
}

.bp3-menu-item.bp3-intent-primary:hover,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item,
.bp3-menu-item.bp3-intent-primary.bp3-active {
  color: #182027 !important;

  background: transparent;
}

.bp3-menu-item.bp3-intent-primary:hover,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item,
.bp3-menu-item.bp3-intent-primary.bp3-active {
  color: #182027 !important;
}

.bp3-menu-item.bp3-intent-primary:hover {
  color: #182027 !important;
  background: rgba(128, 128, 128, 0.2);
}

.bp3-menu-item.bp3-intent-primary:hover,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item,
.bp3-menu-item.bp3-intent-primary:hover::before,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item::before,
.bp3-menu-item.bp3-intent-primary:hover::after,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item::after,
.bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item
  .bp3-menu-item-label,
.bp3-menu-item.bp3-intent-primary:active,
.bp3-menu-item.bp3-intent-primary:active::before,
.bp3-menu-item.bp3-intent-primary:active::after,
.bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label,
.bp3-menu-item.bp3-intent-primary.bp3-active,
.bp3-menu-item.bp3-intent-primary.bp3-active::before,
.bp3-menu-item.bp3-intent-primary.bp3-active::after,
.bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
  color: #5c7080;
}

.bp3-slider-handle .bp3-slider-label {
  width: initial;
}

.bp3-button.bp3-intent-lightcheck {
  background-color: #faefb0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.8)),
    to(rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
  -webkit-box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%),
    inset 0 -1px 0 rgb(16 22 26 / 10%);
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%),
    inset 0 -1px 0 rgb(16 22 26 / 10%);
  color: #182026;
}

.bp3-dialog-header {
  padding-left: 5px;
  border: none !important;
  box-shadow: none !important;
}

.wizardStatValue {
  font-size: 16px;
  font-weight: 400;
}

.wizardLink {
  cursor: pointer;
  color: rgb(191, 115, 38);
  background: rgba(191, 151, 38, 0.1);
  border-bottom: 1px solid rgba(191, 115, 38, 0.3);
  padding-left: 4px;
  padding-right: 4px;
}

.wizardLink:hover {
  cursor: pointer;
  color: #fff;
  background: rgba(191, 151, 38, 1);
  padding-left: 4px;
  padding-right: 4px;
}

.wizardLoader {
  background-color: "pink";
  position: relative;
  top: 50%;
  margin-top: -40px;
  height: 100%;
}

.msg-background::before {
  content: "";
  background-image: url("../../assets/ui/envelope.svg");
  background-size: cover;
  position: absolute;
  width: calc(100vw - 140px);
  height: calc(100vw - 140px);
  max-width: 400px;
  max-height: 400px;
  bottom: -100px;
  right: -100px;
  opacity: 0.025;
  transform: rotate(342deg);
}

.profile-background::before {
  content: "";
  background-image: url("../../assets/ui/user.svg");
  background-size: cover;
  position: absolute;
  width: calc(100vw - 140px);
  height: calc(100vw - 140px);
  max-width: 400px;
  max-height: 400px;
  bottom: -100px;
  right: -100px;
  opacity: 0.025;
  transform: rotate(342deg);
}

.settings-background::before {
  content: "";
  background-image: url("../../assets/ui/cog.svg");
  background-size: cover;
  position: absolute;
  width: calc(100vw - 140px);
  height: calc(100vw - 140px);
  max-width: 400px;
  max-height: 400px;
  bottom: -100px;
  right: -100px;
  opacity: 0.025;
  transform: rotate(342deg);
}

.help-background::before {
  content: "";
  background-image: url("../../assets/ui/help.svg");
  background-size: cover;
  position: absolute;
  width: calc(100vw - 140px);
  height: calc(100vw - 140px);
  max-width: 400px;
  max-height: 400px;
  bottom: -100px;
  right: -100px;
  opacity: 0.025;
  transform: rotate(342deg);
}

.errorBox {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 60;
  margin-bottom: 60;
  margin-right: 40;
  margin-left: 0;
  flex-direction: column;
  color: rgb(219, 55, 55);
}

.errorBox span {
  padding-top: 14px;
  font-weight: bold;
  animation: 1s fadeIn ease-in-out;
}

.bg {
  background-color: #f3f3f3;
}

#wizardContainer {
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.wizardCenteredBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 300px;
  height: calc(100vh - 387px);
  align-items: center;
  justify-content: center;
}

.wizardImage {
  height: 320px;
}

.wizardImageLink {
  opacity: 1;
  padding: 5px;
  display: block;
  background: #fff;
  border-radius: 5px;
}

.wizardImageLink:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.1);
}
.wizardProgressWrapper {
  display: flex;
  background-color: rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  height: 18px;
  margin-bottom: 0px;
  border: 4px solid #e8e3e3;
  margin-left: 40px;
  margin-right: 40px;
}
.wizardProgress {
  border-radius: 6px;
  height: 10px;
  background-color: #4684c8;
  transition: width 0.3s ease-out;
  transition-delay: 0.1s;
}
.meterPhoto {
  opacity: 1;
  border: 2px #fff solid;
  transition: all 0.3s ease-in-out;
}
.meterPhoto:hover {
  opacity: 1;
  border: 2px #c9c9c9 solid;
  transition: all 0.3s ease-in-out;
}

/* ANIMATED ICONS - Success, Danger, Warning */
.svg-box {
  display: inline-block;
  position: relative;
  width: 150px;
}

.blue-stroke {
  stroke: #4684c8;
}

.green-stroke {
  stroke: #0f9960;
}

.red-stroke {
  stroke: #ff6245;
}

.yellow-stroke {
  stroke: #ffc107;
}

.circular circle.path {
  stroke-dasharray: 330;
  stroke-dashoffset: 0;
  stroke-linecap: round; 
  animation: 1s draw-circle ease-out;
}

.loading-data {
  margin-top: 25%;
}

.loadingBlock {
  animation-delay: 3s;
  animation: 1.5s showTime ease-in-out;
}

.circular-loading circle.path {
  opacity: 0.4;
  transform-origin: center;
  animation: 127000ms draw-circle linear;
  fill-rule: nonzero;
}

.circular-loading circle.inner-path {
  opacity: 0.2;
  stroke-width: 5;
  animation-delay: 1s;
  animation: 0.3s draw-circle-opac cubic-bezier(0.22, 1, 0.36, 1);
}

.svg-rotated {
  transform: rotate(-90deg);
}

.timeLeft {
  transform-origin: center;
  transform: rotate(0deg);
  position: absolute;
  width: 100%;
  top: calc(50% - 45px);
  animation: 1.5s showTime cubic-bezier(0.22, 1, 0.36, 1);
}

.animatedIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  flex-direction: column;
}

.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  flex-direction: column;
}

/*------- Checkmark ---------*/
.checkmark {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 56px;
  left: 49px;
  width: 52px;
  height: 40px;
}

.checkmark path {
  animation: 1s draw-check cubic-bezier(0.22, 1, 0.36, 1);
}

.fadeIn {
  animation: 1s fadeIn cubic-bezier(0.65, 0, 0.35, 1);
}

.download-animation {
  position: absolute;
  left: 14px;
  top: 13px;
}

.animate-rotate {
  position: absolute;
  animation: 2s rotate ease-out infinite;
}


/*---------- Cross ----------*/

.cross {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 54px;
  left: 54px;
  width: 40px;
  height: 40px;
}

.cross .first-line {
  animation: 0.3s draw-first-line linear;
}

.cross .second-line {
  animation: 0.6s draw-second-line linear;
  
}


.alert-sign {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 40px;
  left: 68px;
  width: 15px;
  height: 70px;
  animation: 0.5s alert-sign-bounce cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.alert-sign .dot {
  stroke: none;
  fill: #0f9960;
}

.alert-header-danger {
  color: #DB3737 !important;
}

.alert-header-warning {
  color: #ff7240 !important;
}

.alert-header-success {
  color: #0F9960 !important;
}



.printable {
  display: none;
}
.status-light-pattern {
  margin-top: 30px;
}

.budgetWhatsLeft {
  display: flex;
}

.icon-wind-direction {
  height: 12px;
  margin-right: 5px;
}

.animate-fade-in {
  animation: 0.6s fadeIn ease-in;
}

.animate-dropdown {
  animation: 0.6s animateDropDown ease-in;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .budgetWhatsLeft {
    display: none !important;
  }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
  .budgetWhatsLeft {
    display: flex !important;
  }

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .budgetWhatsLeft {
    display: none !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .budgetWhatsLeft {
    display: flex !important;
  }

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  

}
/* PRINT STYLE */

@media print {
  html,
  body {
    height: 100%;
    margin: 0;
    font-family: Rubik;
    padding: 0;
  }

  .wizardStepDot {
    width: 40px !important;
    height: 40px !important;
    padding-top: 10px !important;
  }

  .wizardStepDesc {
    padding: 10px !important;
  }

  .wizardStepDescText {
    font-family: Rubik;
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .wizardStepDesc {
    font-size: 20px !important;
  }

  .mainContentWizard,
  .wizardStep {
    margin: 0;
    padding: 0;
    max-width: 100% !important;
    width: 100%;
  }

  .dashboardLoading{
    width: 200px;
    height: 6px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    transition: opacity 0.3s ease-out;
  }

  .dashboardLoading::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #4684c8;
    border-radius: 20px;
    animation: grow 2s ease-in-out infinite;
  }
  
  .dashboardLoading.loaded {
    display: none;
  }

  #mobileNavigation,
  .no-print {
    display: none;
  }

  .wizardProgressWrapper {
    display: none;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  #instructionsStepsWrapper {
    display: none;
  }

  .wizardStepWrapper {
    width: 335px;
    position: relative;
    display: flex;
    flex: 1 0 50%;
    margin-bottom: 100px;
  }

  .wizardStepImage {
    width: 335px;
    height: 430px;
  }

  .wizardNote {
    font-size: 20px;
    margin-top: -160px;
    position: relative;
    height: 150px;
  }

  .wizardStepDesc {
    position: absolute;
    padding: 8px;
    height: auto;
    line-height: 20px;
    margin-bottom: -96px;
  }

  .wizardStepDescText {
    font-size: 20px;
    color: #000;
  }

  .printable {
    display: flex;
    flex-wrap: wrap;
    margin-top: -50px;
    font-size: 20px;
  }

  .status-light-pattern {
    margin-top: 140px;
  }

  .printable-logo {
    position: absolute;
    left: 50%;
    top: -20px;
    margin-left: -50px;
  }
}

.bp3-icon.bp3-intent-warning, .bp3-icon-standard.bp3-intent-warning, .bp3-icon-large.bp3-intent-warning {
  color: rgb(247, 127, 0);
}

.bp3-toast[class*="bp3-intent-none"] > .bp3-icon {
  color: #5c7080 !important;
}

.bp3-toast[class*="bp3-intent-none"] > .bp3-button-group {
  display: none;
}

.bp3-toast[class*="bp3-intent-none"] > .bp3-toast-message .bp3-progress-bar {
  margin-top: 7px;
}

.setupLink:hover {
  text-decoration: none;
}

/* fix number arrows on input type number on firefox  */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a,visited {
  outline: none !important;
}

.google-map {
  min-height: 700px;
  margin: 2% 2%;
}

.map-tooltip {

  position: absolute;
  width: 160px;
  height: auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  top: -80px;
  left: 50%;
  margin-left: -64px;
  padding: 6px;
}

.map-tooltip::after {
  border: solid;
  border-color: #fff transparent;
  border-width: 12px 10px 0 10px;
  content: "";
  left: 50%;
  bottom: -5px;
  margin-left: -10px;
  position: absolute;
}

.map-tooltip h3 {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  padding-bottom: 12px;
}

.map-tooltip p {
  font-size: 14px;
  line-height: 4px;
}


.logout {
  position: absolute;
  right: 0;
}

.logout-mobile {
  position: absolute;
  left: 50%;
  margin-left: -31.5px;
}

.admin-content {
  padding-top: 80px;
  min-height: 700px;
}

.info-title {
  font-size: 18px;
  color:rgb(0, 0, 0, 0.65);
  padding: 0px 10px;
  text-align: center;
  width: 100%;
  display: block;
  border-bottom: 1px rgba(0,0,0,0.05) solid;
  padding-bottom: 14px;
}

.info-box {
  margin-top: 40px;
  border-radius: 8px;
  padding: 10px;
  min-height: 150px;
  border: 1px rgba(0,0,0,0.05) solid;
  box-shadow: 0px -5px 15px rgba(0,0,0,0.05);
}

.info-content {
  display: flex;
  flex: 1;
  padding: 20px 0px;
  height: 150px;
}

.info-content-small {
  display: flex;
  flex: 1;
  height: 75px;
}

.info-element {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  
}

.info-element-category {
  display: block;
  font-size: 16px;
}

.info-element-value {
  display: block;
  font-size: 16px;
}

.info-box-loading {
  opacity: 0.5;
}

.react-bs-table-sizePerPage-dropdown {
  position: absolute !important;
  left: 9px;
}

#pageDropDown {
  border-radius: 4px;
  background-color: #4583c7 !important;
  margin-bottom: 1em !important;
}

.pagination .page-item.active .page-link{
  background-color: #4583c7 !important;
  border-radius: 2px;
  z-index: 1;
}

.pagination ul a, a {
  display: block;
}

.react-bootstrap-table {
  overflow: auto;
  margin: 2em 0;
}

.react-bootstrap-table tr:hover {
  background-color: rgba(248, 255, 115, 0.2) !important;
}

.table-buttons .bp3-button {
  margin-right: 6px;
  margin-top: 3px;
}

.table-filter {
  margin-top: 3px;
}

.table-loading {
  position: absolute;
  width: 100%;
  height: calc(100% + 10px);
  background-color: rgba(255,255,255,0.75);
  z-index: 100;

}

.form-checkbox {
  margin: 0 auto;
  margin-top: 0px;
  padding: 0px !important;
  padding-top: 4px !important;
  max-width: 280px !important;
}

.rateplan-modal {
  width: 100%;
  max-width: auto;
}

.no-data {
  width: 100%;
}

.sendCommand {
  background-color: rgba(220, 220, 220, 0.4);
  border-radius: 10px;
  margin-top: 20px;
  border: 1px rgba(220,220,220,0.6) solid;
}

.realtimeWidget {
  height: 400px
}

.calendarTooltip {
  top: 60px !important;
  display: block;
  min-width: 134px;
}

.rateplan-title {
  color:rgb(71, 131, 196);
  font-weight: 400;
  width: 100%;
  border-bottom: 1px rgba(0,0,0,0.15) solid;
  padding-bottom: 12px;
  box-shadow: 0px 2px 0px rgba(0,0,0,0.02);
  text-align:left;
}

.rateplan-note {
  width: 100%;
  text-align: left;
  font-size: 14px;
}


.rateplans-editable .rate-period-super-off-peak,
.rateplans-editable .rate-period-off-peak,
.rateplans-editable .rate-period-mid-peak,
.rateplans-editable .rate-period-on-peak,
.rateplans-editable .rate-period-critical-on-peak {
  opacity: 0.5;
  justify-content: flex-start;
}

.rateplans-editable .rate-period-super-off-peak.selected,
.rateplans-editable .rate-period-off-peak.selected,
.rateplans-editable .rate-period-mid-peak.selected,
.rateplans-editable .rate-period-on-peak.selected,
.rateplans-editable .rate-period-critical-on-peak.selected{
  opacity: 1;
  justify-content: flex-start;

}

.rateplans-editable .pill{
 margin-bottom: 10px !important;
 max-height: 4px !important;
}

.editable-rateplan .tier-weekly-summary-histogram {
  background-color: #fff;
}

.editable-rateplan .tierTimeLine {
  margin-top: 0px !important;
  padding: 20px 40px;
}

.editable-rateplan .timeline {
  margin-top: 80px;
}

.editable-rateplan .tier-weekly-summary-title {
  border-bottom: none;
}


.editable-rateplan .tierScheduler {
  margin-bottom: 50px;
}

.editable-rateplan .overrides-schedules {
  border-top: none !important;
  margin-top: 0px !important;
}

.bp3-button-verify {
  overflow: hidden !important;
  position: relative !important;
  height: 40px !important;
  max-width: 220px !important;
}

.bp3-button-verify::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 0px;
  background: rgba(255,255,255,0.25);
  border-radius: 6px;
  animation: progress 60s ease-in-out forwards;
}

.editable-rateplan .inputLabel {
  margin: 0;
}

.editable-rateplan .charges-preview {
  display: flex;
}


.device-details-status {
  position: absolute;
  left: 12px;
  top: 12px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
}

.device-status-cell {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 12px;
  border-radius: 100%;
}

.device-status-online {
  background-color: #0F9960;
}

.device-status-offline {
  background-color: rgb(219, 55, 55);

}

.bp3-slider-handle .bp3-slider-label {
  margin-left: 3px;
}

.editable-rateplan .delete-charge-button {
  position: absolute;
  top: -23px;
  right: 3px;
}

.editable-rateplan .charges-preview {
  flex-wrap: wrap;
  display: flex;
}

.editable-rateplan .charge-preview-bow {
  width: 200px;
}

.editable-rateplan .tier-days {
  border-top: none;
}

.editable-rateplan .hidden {
  display: none;
}

.editable-rateplan .delete-btn {
  max-height: 30px;
  margin-top: 25px;
}

.horizontal-fields {
  display: flex;
}

.horizontal-fields .field {
  padding: 0px 10px;
  width: 33%;
}

.horizontal-fields .bp3-html-select, .bp3-select {
  margin: 0 auto;
  max-width: 280px !important;
}

.bp3-select-ratePlan {
  max-width: 300px !important;
  margin: 0 !important;
}

.bp3-select-ratePlan .bp3-select::after {
  right: 0px;
}

.horizontal-fields .bp3-select {
  margin: 0;
}

.field-50 {
  padding: 0px 10px;
  
  width: 50%;
}

.horizontal-fields .bp3-select-ratePlan {
  min-width: 120px;
  width: 100%;
}

.small-tier-box  {
  height: 80px;
}

.display-none { 
  display: none;
}

.bp3-select::after {
  right: 12px;
}

.form-bigger-labels {
  position: relative;
  top: 32px;
  left: 15px;
  max-width: 120px;
  color: rgb(128, 128, 128);
  font-family: Rubik;
  font-size: 14px;
  text-align: left;
}

.bigger-dialog {
  width: 660px;
}

.emails-content {
  border: 1px #E9E9E9 solid;
  border-radius: 6px;
  padding: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.subject {
  text-align: left;
  max-width: 800px !important;
  margin: 0 auto;
  padding-top: 30px !important;
}

.rich-text-editor {
  max-width: 800px !important;
  min-height:400px;
  margin: 0 auto;
}

.send-mass-email-btn {
  max-width: 800px !important;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 60px;

}

.tag {
  margin-right: 0.5em;
  margin-top: 0.5em;
  padding: 0.5em 1em !important;
}

/* ANIMATINOS */

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showTime {
  0% {
    top: calc(50% + 33px);
    opacity: 0;
  }

  100% {
    top: calc(50% - 45px);
    opacity: 1;
  }
}

@keyframes draw-circle-opac {
  0% {
    stroke-dasharray: 0, 330;
    stroke-dashoffset: 0;
    opacity: 0;
  }

  100% {
    stroke-dasharray: 330, 330;
    stroke-dashoffset: 0;
    opacity: 0.2;
  }
}

@keyframes draw-circle {
  0% {
    stroke-dasharray: 0, 330;
    stroke-dashoffset: 0;
    opacity: 0;
  }

  100% {
    stroke-dasharray: 330, 330;
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

@keyframes draw-check {
  0% {
    stroke-dasharray: 49, 80;
    stroke-dashoffset: 48;
    opacity: 0;
  }
  100% {
    stroke-dasharray: 130, 80;
    stroke-dashoffset: 48;
    opacity: 1;
  }
}

@keyframes draw-first-line {
  0% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  100% {
    stroke-dasharray: 56, 330;
    stroke-dashoffset: 0;
  }
}

@keyframes draw-second-line {
  0% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  100% {
    stroke-dasharray: 56, 330;
    stroke-dashoffset: 0;
  }
}

@keyframes alert-sign-bounce {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes grow {
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

@keyframes animateIcon {
  0% {
    -webkit-transform: opacity(0);
  }
  100% {
    -webkit-transform: opacity(100);
  }
}

@keyframes progress {
  0%{
    width: 0px;
  }
  100%{
    width: 220px;
  }
}

